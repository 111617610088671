<template>
  <AppBreadcrumbs
    v-if="breadcrumbsData?.length && !isHeroSliderFirst"
    :breadcrumbs="breadcrumbsData"
    :scroll-to-right="true"
    class="container"
  />
  <component
    :is="useT3DynamicBl(pageData?.appearance?.backendLayout)"
    v-if="pageData"
    :content="pageData.content"
    :style="cssVariables"
  />
</template>

<script lang="ts" setup>
import type { AppBreadcrumb } from '@base/components/AppBreadcrumbs/AppBreadcrumbs.types'
import type { UseHeadInput } from '@unhead/vue'
import { AppearanceTheme, CmsPageProviderKey } from '../types/PageData.types'
import type { CurrentTheme } from '../types/PageData.types'
import { _colors as _tailwindThemeColors, _backgroundImage as tailwindThemeBackgroundImages } from '#tailwind-config/theme'

const currentTheme = computed<CurrentTheme>(() => {
  switch (pageData.value?.appearance?.theme) {
    case AppearanceTheme.ACADAEMY: {
      return {
        name: AppearanceTheme.ACADAEMY,
        color: _tailwindThemeColors.green.DEFAULT,
        gradient: tailwindThemeBackgroundImages['gradient-green'],
      }
    }
    case AppearanceTheme.SOFTWARE: {
      return {
        name: AppearanceTheme.SOFTWARE,
        color: _tailwindThemeColors.blue[150],
        gradient: tailwindThemeBackgroundImages['gradient-blue'],
      }
    }
    case AppearanceTheme.SERVICES: {
      return {
        name: AppearanceTheme.SERVICES,
        color: _tailwindThemeColors.secondary.DEFAULT,
        gradient: tailwindThemeBackgroundImages['gradient-orange'],
      }
    }
    default: {
      return {
        name: 'default',
        color: _tailwindThemeColors.primary.DEFAULT,
        gradient: tailwindThemeBackgroundImages['gradient-blue'],
      }
    }
  }
})

// css variables are bound to whole page, so they are easilly accessible in each and every component
const cssVariables = computed(() => {
  return {
    '--current-theme-color': currentTheme.value.color,
    '--current-theme-gradient': currentTheme.value.gradient,
  }
})
const { pageData } = await useT3Page()
const currentLocale = useT3i18nState()

definePageMeta({ layout: 'default', cmsPage: true })

// set SEO data
const seoData = ref<UseHeadInput>({
  meta: [],
  link: [],
  title: '',
})
watch(pageData, () => {
  seoData.value = {
    meta: pageData.value?.seo?.meta || [],
    link: pageData.value?.seo?.link || [],
    title: pageData.value?.seo?.title || '',
    htmlAttrs: {
      lang: currentLocale.value,
    },
  }
}, { immediate: true, deep: true })
useHead(seoData.value)

const breadcrumbsData = computed((): Array<AppBreadcrumb> => {
  return pageData.value?.breadcrumbs || []
})

const isHeroSliderFirst = computed(() => {
  const firstContentElement = pageData.value?.content?.colPos0?.[0]
  return (
    firstContentElement?.type === 'rawlplug_slider'
    && firstContentElement?.appearance?.frameClass === 'none'
  )
})

provide(CmsPageProviderKey, {
  currentTheme,
})
</script>
